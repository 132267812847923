<template>
    <BasePopover
        size="lg"
        :popover-classes="((Object.keys(validation.errors).length === 0) ? 'hidden' : '') + ' flex-auto rounded-xl overflow-hidden text-sm leading-6 shadow-lg ring-1 dark:ring-gray-300 ring-gray-900/5'"
        :y-position="yPosition">
        <Alert type="error">
            <div class="font-bold">
                Es sind folgende Fehler aufgetreten:
            </div>

            <ul class="pl-6">
                <li
                    v-for="(error, field) in validation.errors"
                    :key="error"
                    class="list-disc hover:underline">
                    <NuxtLink
                        :to="{path:useLocalePath()(basePath + formProcessStore.formProcessData.id),
                              hash: '#' + (field.startsWith($t('formProcesses.event.title')) ? 'event' : field)}">
                        {{ error }}
                    </NuxtLink>
                </li>
            </ul>
        </Alert>

        <template #trigger>
            <slot name="trigger" />
        </template>
    </BasePopover>
</template>

<script setup>
import {useFormProcessStore} from "@/store/formProcess";

const formProcessStore = useFormProcessStore()

defineProps({
    validation: {
        type: Object,
        required: true,
    },
    yPosition: {
        type: String,
        default: 'top',
    },
    basePath: {
        type: String,
        default: '/formProcess/',
    },
})
</script>