<template>
    <div :class="'flex justify-' + justifyButtons + ' mt-10'">
        <div class="flex gap-4">
            <BaseButton
                v-if="!hideCancelButton"
                :disabled="loading"
                @click="emits('cancel', $event)">
                <span v-if="!loading">{{ cancelLabel }}</span>
                <span v-else>
                    <LoadingSpinner />
                </span>
            </BaseButton>

            <BaseButton
                v-if="showRemoveButton"
                color="danger"
                :disabled="loading || (validation ? !!Object.keys(validation.errors).length : false)"
                @mouseenter="validation ? validation.validate() : null"
                @click="emits('remove', $event)">
                <span v-if="!loading">{{ removeLabel }}</span>
                <span v-else>
                    <LoadingSpinner />
                </span>
            </BaseButton>
        </div>

        <div
            v-if="!hideSubmitButton"
            class="inline-flex">
            <ErrorPopover
                v-if="showErrorPopover"
                :validation="validation">
                <template #trigger>
                    <BaseButton
                        :class="{'rounded-r-none': showCreateButton,'ml-4':showRemoveButton || !hideCancelButton}"
                        :disabled="loading || (validation ? !!(Object.keys(validation.errors).length || !validation.meta.valid) : false)"
                        color="primary"
                        type="submit"
                        @click="emits('submit', $event)"
                        @mouseenter="validation ? validation.validate() : null">
                        <span v-if="!loading">{{ submitLabel }}</span>
                        <span v-else>
                            <LoadingSpinner />
                        </span>
                    </BaseButton>
                </template>
            </ErrorPopover>
            <BaseButton
                v-else
                :class="{'rounded-r-none': showCreateButton,'ml-4':showRemoveButton || !hideCancelButton}"
                :disabled="loading || (validation ? !!(Object.keys(validation.errors).length || !validation.meta.valid) : false)"
                color="primary"
                type="submit"
                @click="emits('submit', $event)"
                @mouseenter="validation ? validation.validate() : null">
                <span v-if="!loading">{{ submitLabel }}</span>
                <span v-else>
                    <LoadingSpinner />
                </span>
            </BaseButton>

            <Menu
                v-if="showCreateButton"
                as="div"
                class="relative block">
                <MenuButton>
                    <BaseButton
                        class="pl-2 pr-2 rounded-l-none"
                        color="primary"
                        :disabled="loading || (validation ? !!(Object.keys(validation.errors).length || !validation.meta.touched) : false)"
                        @mouseenter="validation ? validation.validate() : null">
                        <Icon
                            class="h-5 w-5"
                            name="heroicons:chevron-down"
                            aria-hidden="true" />
                    </BaseButton>
                </MenuButton>
                <Transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95">
                    <MenuItems
                        class="absolute right-0 z-10 w-max origin-top-right rounded-r-md shadow-lg focus:outline-none">
                        <div class="mt-1">
                            <MenuItem>
                                <BaseButton
                                    color="primary"
                                    :disabled="loading || (validation ? !!Object.keys(validation.errors).length : false)"
                                    @click="emits('create', $event)"
                                    @mouseenter="validation ? validation.validate() : null">
                                    <span v-if="!loading">{{ createLabel }}</span>
                                    <span v-else>
                                        <LoadingSpinner />
                                    </span>
                                </BaseButton>
                            </MenuItem>
                        </div>
                    </MenuItems>
                </Transition>
            </Menu>
        </div>
    </div>
</template>

<script setup>

defineProps({
    loading: {type: Boolean, default: false},
    validation: {type: Object, default: null},
    hideCancelButton: {type: Boolean, default: false},
    hideSubmitButton: {type: Boolean, default: false},
    showRemoveButton: {type: Boolean, default: false},
    showCreateButton: {type: Boolean, default: false},
    cancelLabel: {type: String, default: 'Abbrechen'},
    submitLabel: {type: String, default: 'Speichern'},
    removeLabel: {type: String, default: 'Löschen'},
    createLabel: {type: String, default: 'Speichern und weiter'},
    justifyButtons: {type: String, default: 'end'},
    showErrorPopover: {type: Boolean, default: false}
})

const emits = defineEmits(['cancel', 'submit', 'remove', 'create'])
</script>

<style scoped>

</style>